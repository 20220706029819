<template>
    <div class="root">
        <el-card class="box-card" v-loading="loading">
            <div slot="header" class="clearfix">
                <span>{{$route.name}}</span>
            </div>

            <el-input class="text-input" type="textarea" placeholder="请输入内容" v-model="text"></el-input>

            <div class="btn-box">
                <span>{{ text.length }} 字</span>
                <el-button size="small" type="primary" @click="submit">保存</el-button>
            </div>

        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                text: '',
                loading: true
            }
        },
        created() {
            this.loadData()
        },
        methods: {
            // 表格请求
            loadData() {
                let that = this
                this.ajax({
                    url: '/file/icon',
                    beforeSend() {
                        that.loading = true
                    },
                    complete() {
                        that.loading = false
                    },
                    success(res) {
                        that.text = res.data
                    },
                    error(res) {
                        that.$message.error(res.message)
                    },
                })
            },
            submit() {
                let that = this
                if (that.text.replace(/ /g, '')) {
                    this.ajax({
                        url: '/file/icon',
                        type: 'post',
                        data: {
                            text: that.text
                        },
                        beforeSend() {
                            that.loading = true
                        },
                        complete() {
                            that.loading = false
                        },
                        success() {
                            that.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                        },
                        error(res) {
                            that.$message.error(res.message)
                        },
                    })
                } else {
                    that.$message.warning('请输入内容')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .root,
    .box-card {
        height: 100%;
    }

    .text-input {
        height: calc(100vh - 280px);

        /deep/textarea {
            height: 100%;
            resize: none;
        }
    }

    .btn-box {
        span {
            margin-right: 20px;
            vertical-align: sub;
        }

        padding-top: 20px;
        text-align: right;
    }
</style>